import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

// const App = () => {
//   const [showForm, setShowForm] = useState(false);
//
//   const handleButtonClick = () => {
//     setShowForm(true);
//   };
//
//   return (
//       <div className="root">
//       <div className="container">
//           <div className="header">
//               <div className="header-title">
//                   <h1>Compassion Today</h1>
//               </div>
//           </div>
//           {showForm ? (
//             <Form />
//           ) : (
//             <div>
//             <div className="header-subtitle">
//                 <h2>We got it!</h2>
//             </div>
//               <p>Please click the button below to show the form</p>
//               <button onClick={handleButtonClick}>Show Form</button>
//             </div>
//           )}
//       </div>
//       </div>
//   );
// };

// Form
// {formSubmitted ? (
//     <p>Thank you! We'll contact you when we give you access!</p>
// ) : (
//     <div>
//     <div className="header-subtitle">
//         <h2>Sign up to get early access.</h2>
//     </div>
//     <div className="form">
//         <form onSubmit={handleSubmit}>
//           <label>
//             Name
//             </label>
//             <input
//               type="text"
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//             />
//           <br /><br />
//           <label for="email">Email</label>
//             <input
//               type="email"
//               id="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//           <br /><br />
//           <label for="phone">Phone</label>
//           <input type="tel" id="phone"
//             placeholder="+10123456789"
//               value={phone}
//               onChange={(e) => setPhone(e.target.value)}
//           />
//           <br/><br/>
//           <label for="hour">Hour of Day (when you want to get messages)</label>
//           <input type="number" id="hour"  min="1" max="24"
//           value={hour}
//           onChange={(e) => setHour(e.target.value)}
//           /><br/><br/>
//
//           <button type="submit">Let's go!</button>
//         </form>
//     </div>
//     </div>
// )}

const Form = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [hour, setHour] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create form data object
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);

    // // Post form data to URL
    // fetch('https://hooks.airtable.com/workflows/v1/genericWebhook/appvtXwU6pbZhgWi4/wflPlqtDysp9uba2C/wtrLTlsfOTTTjAL1e', {
    //   method: 'POST',
    //   body: formData
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     // Handle response from server
    //     console.log('Form submitted successfully:', data);
    //   })
    //   .catch(error => {
    //     // Handle error
    //     console.error('Form submission failed:', error);
    //  });    // Send form data to server or perform other actions
    console.log('Form submitted:', { name, email });
    // Reset form fields
    setFormSubmitted(true);
    setName('');
    setEmail('');
  };

  return (
      <div className="root">
      <div className="container">
        <div className="header">
          <div className="header-title">
              <h1>Compassion Today</h1>
          </div>
          <div className="header-subtitle">
              <h2>Start your journey towards self-love today</h2>
          </div>
          <br />
          <img src={'/cover-beta.png'} style={{borderRadius: '50%', width:'400px'}} />
        </div>
        <div className='demo'>
            <div className="header-subtitle">
                <h2>Personalized messages on your phone that help you learn to be kinder to yourself.</h2>
            </div>
            <div className='assistant'>
            Compassion Today check-in: How are you feeling today?
            </div>
            <div className='user'>
            Today is really busy, I'm feeling overwhelmed about all of the things I need to do.
            </div>
            <div className='assistant'>
            It sounds like you have a lot on your plate today!
            Remember that it's okay to take things one step at a time.
            Try to break down your tasks into smaller, more manageable parts.
            You got this! 💪 <br/><br/>
            One exercise you can try is called "self-compassion break."
            When you're feeling stressed and overwhelmed, take a few deep breaths and tell yourself:
            "This is a moment of suffering. Suffering is a part of life. May I be kind to myself in this moment."
            It may seem simple, but it can really help shift our mindset and cultivate more self-compassion. ❤️
            </div>
        </div>
        <div className='demo'>
            <div className="header-subtitle">
                <h2>Plus, a message once a week that shows you your progress.</h2>
            </div>
            <div className='header-title'>
            <h1>💌</h1>
            </div>
        </div>
        <div>
            <iframe class="airtable-embed"
                src="https://airtable.com/embed/shrsdQauCQ6Y2fX42?backgroundColor=blue"
                frameborder="0" onmousewheel=""
                width="400" height="700"
                style={{background: 'transparent', border: '1px solid #ccc', borderRadius: '10px'}}></iframe>
        </div>
    </div>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Form />
  </React.StrictMode>
);
